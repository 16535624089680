import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/1440p_Series_CGI_List/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "1440p CGI Commands",
  "path": "/1440p_Series_CGI_List/Features_Menu/Email/",
  "dateChanged": "2018-03-23",
  "author": "Mike Polinowski",
  "excerpt": "Camera API control for developers",
  "image": "../../AU_SearchThumb_CGICommands_1080p.png",
  "social": "/images/Search/AU_SearchThumb_CGICommands_1080p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_CGIs_white.webp",
  "chapter": "1440p CGI Commands"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='1440p Series CGI List' dateChanged='2019-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='1440p Series CGI List' image='/images/Search/AU_SearchThumb_CGICommands_1440p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_1440p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/1440p_Serie_CGI_Befehle/' locationFR='/fr/1440p_Series_CGI_List/' crumbLabel="Email" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "the-features-menu--email",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-features-menu--email",
        "aria-label": "the features menu  email permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Features Menu :: Email`}</h1>
    <EuiSpacer mdxType="EuiSpacer" />
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-features-menu--email"
        }}>{`The Features Menu :: Email`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetsmtpattr"
            }}>{`param.cgi?cmd=getsmtpattr`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-email-server-parameter"
                }}>{`Get Email Server Parameter`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdtestsmtp"
            }}>{`param.cgi?cmd=testsmtp`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#test-email-server-parameter"
                }}>{`Test Email Server Parameter`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-1"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetmaaction"
            }}>{`param.cgi?cmd=getmaaction`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#enable--disables-alarm-email-snapshots"
                }}>{`Enable / Disables Alarm EMail Snapshots`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-2"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetmaactionattr"
            }}>{`param.cgi?cmd=getmaactionattr`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-email-notification-parameter"
                }}>{`Get Email Notification Parameter`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-3"
                }}>{`Example`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <hr></hr>
    <h2 {...{
      "id": "paramcgicmdgetsmtpattr",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetsmtpattr",
        "aria-label": "paramcgicmdgetsmtpattr permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getsmtpattr`}</h2>
    <h3 {...{
      "id": "get-email-server-parameter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-email-server-parameter",
        "aria-label": "get email server parameter permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get Email Server Parameter`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`ma_server`}</code>{`: SMTP Server Address e.g. smtp.strato.de`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`ma_port`}</code>{`: SMTP Server Port e.g. `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`25`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`587`}</code>{` (TLS) or `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`465`}</code>{` (SSL)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`ma_ssl`}</code>{`: SSL Encryption `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`0`}</code>{` (Disabled), `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`1`}</code>{` (SSL), `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`2`}</code>{` (TLS) or `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`3`}</code>{` (STARTTLS)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`ma_username`}</code>{`: SMTP Server Username`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`ma_password`}</code>{`: SMTP Server Password `}<strong parentName="li">{`Note`}</strong>{`: The password can be SET but `}<strong parentName="li">{`will not`}</strong>{` be returned in a GET request!`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`ma_from`}</code>{`: Sender Email Address`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`ma_to`}</code>{`: Receiver Email Addresses. Append email addresses in order to send to multiple receivers. eg: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`test@instar.de,test2@instar.de`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`ma_subject`}</code>{`: Mail subject line`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`ma_tsubject`}</code>{`: Photoseries mail subject line`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`ma_text`}</code>{`: Mail Content`}</li>
    </ul>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getsmtpattr

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getsmtpattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_server`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"my.mailserver.com"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_port`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"587"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_ssl`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"3"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_username`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cam@my.email"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_from`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cam@my.email"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_to`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cam@web.de"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_subject`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"MyCam"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_text`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Hello"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getsmtpattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getsmtpattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_server`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"my.mailserver.com"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_port`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"587"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_ssl`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"3"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_username`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cam@my.email"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_from`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cam@my.email"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_to`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cam@web.de"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_subject`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"MyCam"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_text`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Hello"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setsmtpattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_ssl`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_from`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`cam%40instar.email`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_to`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`me@gmail.com%3B`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_subject`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Alarm%20Email`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_text`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`ALARM`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_server`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`mx.instar.email`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_port`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`587`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_username`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`cam%40instar.email`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ma_password`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`kunde123

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"setsmtpattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdtestsmtp",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdtestsmtp",
        "aria-label": "paramcgicmdtestsmtp permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=testsmtp`}</h2>
    <h3 {...{
      "id": "test-email-server-parameter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#test-email-server-parameter",
        "aria-label": "test email server parameter permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Test Email Server Parameter`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`result`}</code>{`:`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`0`}</code>{`: Success`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-1`}</code>{`: Can’t connect to Server`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-2`}</code>{`: Invalid credentials`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-3`}</code>{`: Permission error / Bad path`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-4`}</code>{`: Timeout`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-5`}</code>{`: Server cannot be verified`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-6`}</code>{`: Busy`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-7`}</code>{`: Internal error`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-8`}</code>{`: Unknown error`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-9`}</code>{`: Transfer incomplete`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "example-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-1",
        "aria-label": "example 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`testsmtp

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`result`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgetmaaction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetmaaction",
        "aria-label": "paramcgicmdgetmaaction permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getmaaction`}</h2>
    <h3 {...{
      "id": "enable--disables-alarm-email-snapshots",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#enable--disables-alarm-email-snapshots",
        "aria-label": "enable  disables alarm email snapshots permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Enable / Disables Alarm EMail Snapshots`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`enable`}</code>{`: De/Activate alarm recording `}{`[0, 1]`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`interval`}</code>{`: Cooldown time between Mails `}{`[30 - 86399]`}{` s`}</li>
    </ul>
    <h3 {...{
      "id": "example-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-2",
        "aria-label": "example 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getmaaction

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getmaaction"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`interval`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"60"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getmaaction`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getmaaction"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`interval`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"60"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmaaction`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"setalarmrecattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgetmaactionattr",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetmaactionattr",
        "aria-label": "paramcgicmdgetmaactionattr permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getmaactionattr`}</h2>
    <h3 {...{
      "id": "get-email-notification-parameter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-email-notification-parameter",
        "aria-label": "get email notification parameter permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get Email Notification Parameter`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`snapcount`}</code>{`: Attach snapshot to email `}{`[0-10]`}</li>
    </ul>
    <h3 {...{
      "id": "example-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-3",
        "aria-label": "example 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getmaactionattr

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getmaactionattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`snapcount`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getmaactionattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getmaactionattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`snapcount`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmaactionattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`snapcount`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"setmaactionattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      